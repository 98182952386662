import React, {useEffect} from 'react';

function Service()
{

    useEffect(() =>{
        document.title = "Services";
    },[]);

    return(
        <div id='servicesPage'>
            <section className="masthead">
                <div>
                    <h1>My Services</h1>
                </div>
            </section>
            <section className="container">
                <section className="row">
                    <figure className="col-lg-4">
                        <img src="./Assets/code-image.jpg" alt="code image"/>
                        <div>
                            <h2 className="fw-normal">Front End Development</h2>
                            <p>Develope the front end for your next webpage using HTML, CSS and JavaScript/TypeScript. Also can work with templates like Bootstrap.</p>
                        </div>
                    </figure>{/* <!-- /.col-lg-4 --> */}
                    <figure className="col-lg-4">
                        <img src="./Assets/UX_UI.png" alt="ux/ui image"/>
                        <div>
                            <h2 className="fw-normal">User Experiences Developer</h2>
                            <p>Can help improve the user experiences of your current or future website or webapp.</p>
                        </div>
                    </figure>{/* <!-- /.col-lg-4 --> */}
                    <figure className="col-lg-4">
                        <img src="./Assets/Color.png" alt="colour wheel"/>
                        <div>
                            <h2 className="fw-normal">Web Designer</h2>
                            <p>Can work with principles and elements of design to help improve simple websites designs by improving colour palettes, typeface and the flow of the layout.</p>
                        </div>
                    </figure>{/* <!-- /.col-lg-4 --> */}
                </section>{/* <!-- /.row --> */}
            </section>
        </div>
    )
}

export default Service;