import React, {useEffect} from 'react';

function Contact()
{

	useEffect(() =>{
        document.title = "Contact";
    },[]);

    return(
        <div id="contact">
			<section className="masthead">
                <div>
					<h1>Contact Form - Coming Soon</h1>
                </div>
            </section>
			<div id="errorMessage" className="alert alert-danger" role="alert" hidden>
				
			</div>
			<section className="container">
				<form id="contactForm">
					<div className="mb-3">
						<label htmlFor="firstName" className="form-label">First Name</label>
						<input type="text" className="form-control" id="firstName" name="firstName" aria-describedby="firstName" placeholder="Enter your First Name" required autoFocus />
					</div>

					<div className="mb-3">
						<label htmlFor="lastName" className="form-label">Last Name</label>
						<input type="text" className="form-control" id="lastName" name="lastName" aria-describedby="lastName" placeholder="Enter your Last Name" required />
					</div>

					<div className="mb-3">
						<label htmlFor="contactNumber" className="form-label">Contact Number</label>
						<input type="tel" className="form-control" id="contactNumber" name="contactNumber" aria-describedby="contactNumber" placeholder="Enter your Contact Number" required />
					</div>

					<div className="mb-3">
						<label htmlFor="emailAddress" className="form-label">Email address</label>
						<input type="email" className="form-control" id="emailAddress" name="emailAddress" aria-describedby="emailAddress" placeholder="Enter your Email" required />
					</div>

					<div className="mb-3">
						<label htmlFor="shortMessage" className="form-label">Your Message</label>
						<textarea className="form-control" id="shortMessage" name="shortMessage" aria-describedby="shortMessage"></textarea>
					</div>

					<button id="submitButton" type="submit" className="btn btn-primary btn-lg"><i className="fa-solid fa-square-envelope"></i>  Send</button>
					
					<button id="resetButton" type="reset" className="btn btn-warning btn-lg"><i className="fa-solid fa-comment-slash"></i> Reset</button>
				</form>
			</section>

		</div>
    )
}

export default Contact;