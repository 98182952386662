import React from 'react';

function Footer()
{
    return(
        /* <!-- fixed footer --> */
        <nav className="navbar fixed-bottom text-light">
            <div className="container-fluid">
                <h5>All Rights Reserved. Vulcan-Design. &copy; Copyright 2022.</h5>
            </div>
        </nav>
    )
}

export default Footer;