import React, {useEffect} from 'react';

function About()
{

	useEffect(() =>{
        document.title = "About";
    },[]);

    return(
        <div id="about">
			<section className="masthead">
                <div>
					<h1>About Me and My Journey</h1>
                </div>
            </section>
			{/* <!-- Dropdown --> */}
			<article className="container">
				<section className="accordion" id="accordionExample">
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingOne">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">About Me</button>
						</h2>
						<div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
							<p className="accordion-body">Hi my name is <strong>Alex Taylor</strong> and I am an Interactive Media Design - Web student at Georgian College. I am a huge fan of video games, movies and hockey. I enjoy playing video games and watching movies when I'm not doing school work or improving my code. When I need to take a break and relax, I look to video games, watch a movie or watch the Leafs game whenever ones on.</p>
							<ul>
								<li>Top Movie: Lord of the Rings trilogy</li>
								<li>Top Game: Assassin's Creed series</li>
							</ul>
						</div>
					</div>

					<div className="accordion-item">
						<h2 className="accordion-header" id="headingTwo">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">My Journey</button>
						</h2>
						<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
							<p className="accordion-body">I have been through a lot to get where I am now. I faced depression and social anxiety throughout high school, and even after that until my family helped me seek professional help in 2019. Together, we set goals, and one of them was to attend a college course. After working hard and becoming more comfortable with myself, I attended my first class since high school - Computer Programming HTML Fundamentals, in 2020. During the class, the teacher shared how he went from being a computer programming student to an interactive web designer student. Upon hearing his story, I was able to resonate with it and it sparked a new way of learning for me. This experience led me to change my major to Interactive Media Design - Web. Since then, my journey in this field has been both exciting and fulfilling, driven by my passion for creativity and innovation..</p>
						</div>
					</div>
				</section>
				{/* <!-- right side images --> */}
				<section>
					<img src="./Assets/AssassinsCreed-Image.jpg" alt=""/>
					<img src="./Assets/LOTR-Image.jpg" alt=""/>
					<img src="./Assets/Leafs-Image.jpg" alt=""/>
				</section>
			</article>
		</div>
    )
}

export default About;