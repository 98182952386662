import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';

function Home()
{

    useEffect(() =>{
        document.title = "Home";
    },[]);

    return(
        <div id="home">
            <section className="container">
                <section className="card">
                    <article className="home-text">
                        <h3>Hello, I'm</h3>
                        <h1>Alex Taylor</h1>
                        <h5>Web Developer & Designer <span>From Innisfil</span></h5>
                        <p>Student of Interactive Web Design and Development. Looking forward talking with you!</p>
                        <div className="social">
                            <a href="#"><i className="fa-brands fa-twitter fa-lg"></i></a>
                            <a href="https://github.com/DarkHavoc88"><i className="fa-brands fa-github fa-lg"></i></a>
                            <a href="https://www.linkedin.com/in/alex-taylor-800020251/"><i className="fa-brands fa-linkedin-in fa-lg"></i></a>
                        </div>
                        <NavLink to={"/about"} className="btn">About</NavLink>
                        <NavLink to={"/contact"} className="btn">Contact Me</NavLink>
                    </article>
                </section>
    
                <section>
                    <img src="./Assets/ProfilePhoto.png" alt="Photo of myself"/>
                </section>
            </section>
        </div>
    )
}

export default Home;