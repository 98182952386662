import React, {useEffect} from 'react';

function Project()
{

    useEffect(() =>{
        document.title = "Projects";
    },[]);

    return(
        <div id='projectsPage'>
            <section className="masthead">
                <div>
                    <h1>My Projects</h1>
                </div>
            </section>
            <section className="card-group" id="card-group">
                <article className="card">
                    <img src="./Assets/Design-Final.png" className="card-img-top" alt="Old Portfolio design"/>
                    <figcaption className="card-body">
                        <h3 className="card-title">Previous Portfolio Design</h3>
                        <p className="card-text">This was my final project in Digital Design Principles className. It holds all that I learn in the course like how to work with colour palettes, how to make font look pleasant with typeface and what goes into a well design logo.I was graded very well on this project.</p>
                    </figcaption>
                </article>
                <article className="card">
                    <img src="./Assets/JavaScript-Final.png" className="card-img-top" alt="Weather App project"/>
                    <figcaption className="card-body">
                        <h3 className="card-title">Weather App</h3>
                        <p className="card-text">This was our final team JavaScript project it was to get data from an API. We choose to make a weather web app that would get the temperature and what type of weather was happening of whatever city the user enters.</p>
                    </figcaption>
                </article>
                <article className="card">
                    <img src="./Assets/PHP-Final.png" className="card-img-top" alt="PHP working CRUD project"/>
                    <figcaption className="card-body">
                        <h3 className="card-title">PHP Final Project</h3>
                        <p className="card-text">This was a full working CRUD system. The user can add and update data to the database but only if the user is logged in as an admin could the data be removed.</p>
                    </figcaption>
                </article>
            </section>
        </div>
    )
}

export default Project;