import React from "react";
import {NavLink} from 'react-router-dom';

function Header()
{
	return(
		<nav className="navbar navbar-expand-lg navbar-dark">
			<div className="container-fluid"> 
				{/* <!-- logo --> */}
				<NavLink to={"/home"} className="navbar-brand">
				<img src="./Assets/Logo.png" alt="My Logo"/>
				</NavLink>
				{/* <!-- hamburger menu --> */}
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
				</button>
				{/* <!-- nav bar --> */}
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
						<li className="nav-item">
							<NavLink to={"/home"} className="nav-link" id="Home" aria-current="page"><i className="fa-solid fa-house-chimney fa-lg"></i> Home</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={"/about"} className="nav-link" id="About" aria-current="page"><i className="fa-solid fa-circle-info fa-lg"></i> About</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={"/project"} className="nav-link" id="Projects" aria-current="page"><i className="fa-solid fa-box-archive fa-lg"></i> My Projects</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={"/service"} className="nav-link" id="Services" aria-current="page"><i className="fa-solid fa-clipboard-list fa-lg"></i> My Services</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={"/contact"} className="nav-link" id="Contact" aria-current="page"><i className="fa-solid fa-envelope fa-lg"></i> Contact Me</NavLink>
						</li>
					</ul>{/* <!-- .navbar-nav --> */}
				</div>{/* <!-- .navbar-collapse --> */}
			</div>{/* <!-- .container-fluid --> */}
		</nav>
	)
}

export default Header;